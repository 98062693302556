/* import __COLOCATED_TEMPLATE__ from './fin-messenger-identity.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { type PulseAccordion } from 'glint/pulse';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

interface Args {
  accordion: PulseAccordion;
}

export default class StandaloneZendeskFinMessengerIdentity extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;

  @tracked identityPreviewImgSrc = '';

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig!;
  }

  get isLoading() {
    return this.finStandaloneService.isLoadingOperatorIdentity;
  }

  @action
  setIdentityPreviewImgSrc(url: string) {
    this.identityPreviewImgSrc = url;
  }

  @action async save() {
    await this.finStandaloneService.operatorIdentity.save();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::Sunshine::FinMessengerIdentity': typeof StandaloneZendeskFinMessengerIdentity;
    'standalone/zendesk/deploy/sunshine/fin-messenger-identity': typeof StandaloneZendeskFinMessengerIdentity;
  }
}
