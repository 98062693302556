/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import templateOnly from '@ember/component/template-only';

export interface Args {}

let StandaloneZendeskSettings = templateOnly<Args>();

export default StandaloneZendeskSettings;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Settings': typeof StandaloneZendeskSettings;
    'standalone/zendesk/settings': typeof StandaloneZendeskSettings;
  }
}
