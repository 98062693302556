/* import __COLOCATED_TEMPLATE__ from './tickets.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

interface Args {}

export default class StandaloneZendeskDeployTickets extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;

  get zendeskConfig() {
    return this.finStandaloneService.zendeskConfig!;
  }

  @tracked openSectionId?: string;

  @action setOpenSectionId(id: string | undefined) {
    this.openSectionId = id;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::Tickets': typeof StandaloneZendeskDeployTickets;
    'standalone/zendesk/Deploy/tickets': typeof StandaloneZendeskDeployTickets;
  }
}
