/* import __COLOCATED_TEMPLATE__ from './action-buttons-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import extractResponseError from 'embercom/lib/extract-response-error';

export default class ActionButtonsComponent extends Component {
  @service notificationsService;
  @service intercomEventService;
  @service modelDataCacheService;
  @service permissionsService;
  @service intl;
  @service intercomConfirmService;
  @service guideLibraryService;

  @tracked app = this.args.app;
  @tracked team = this.args.team;
  @tracked teamSettings = this.args.teamSettings;
  @tracked accordion = this.args.accordion;
  @tracked sectionName = this.args.sectionName;
  @tracked defaultSectionName = this.args.defaultSectionName;
  @tracked selectedTeamStatus = this.team.isNew ? 'creating' : 'editing';
  @tracked isSaving = false;

  confirmOptions = {
    title: this.intl.t('components.settings.teams.action-buttons-component.confirm-options.title'),
    body: this.intl.t('components.settings.teams.action-buttons-component.confirm-options.body'),
    cancelButtonText: this.intl.t(
      'components.settings.teams.action-buttons-component.confirm-options.cancel-button',
    ),
    confirmButtonText: this.intl.t(
      'components.settings.teams.action-buttons-component.confirm-options.confirm-button',
    ),
  };

  get isCreating() {
    return this.selectedTeamStatus === 'creating';
  }

  get shouldDisplayDirtyConfirmation() {
    return (
      this.team.hasDirtyAttributes || this.teamSettings.hasDirtyAttributes || this.args.teamUpdated
    );
  }

  @action
  async saveExistingTeam() {
    try {
      await this.team.save();
    } catch (error) {
      if (error.jqXHR.status === 403) {
        return this.notificationsService.notifyError(error.jqXHR.responseJSON.message);
      }
      throw error;
    }

    if (this.permissionsService.currentAdminCan('can_manage_workload_management')) {
      await this.teamSettings.save();
    } else {
      // We're only updating the distribution method on teamSetting, so we use a custom endpoint
      // as the main update endpoint requires the can_manage_workload_management permission, which some
      // teammates may not have
      await this.teamSettings.save({ adapterOptions: { onlyUpdateDistributionMethod: true } });
    }

    try {
      await this.args.updateTeamMemberships();
    } catch (error) {
      if (error.jqXHR.status === 403) {
        return this.notificationsService.notifyError(error.jqXHR.responseJSON.message);
      }
      throw error;
    }

    this.notificationsService.notifyConfirmation(
      this.intl.t(
        'components.settings.teams.action-buttons-component.notifications.save-existing-team.confirmation',
      ),
    );

    // When we update selectedTeam, we update the underlying _data after saving the selectedTeam,
    // but are not updating the underlying data of app, which is what is used to update local storage.
    // So to effectively update local storage in that case, we have to manually remove then add the team
    // back on the app model directly. Source: https://github.com/intercom/embercom/pull/24893
    this.app.removeTeam(this.team);
    this.app.addTeam(this.team);

    this.accordion.publicAPI.closeSection(this.sectionName);
  }

  @action
  async saveNewTeam() {
    try {
      await this.team.save();
    } catch (error) {
      if (error.jqXHR.status === 403) {
        return this.notificationsService.notifyError(error.jqXHR.responseJSON.message);
      }
      throw error;
    }
    this.teamSettings.set('teamId', this.team.id);

    if (this.permissionsService.currentAdminCan('can_manage_workload_management')) {
      await this.teamSettings.save();
    } else {
      // We're only creating a TeamSetting with a specific distribution method, so we use a custom endpoint
      // as the main create endpoint requires the can_manage_workload_management permission, which some
      // teammates may not have
      await this.teamSettings.save({ adapterOptions: { onlyUpdateDistributionMethod: true } });
    }
    try {
      await this.args.updateTeamMemberships();
    } catch (error) {
      if (error.jqXHR.status === 403) {
        return this.notificationsService.notifyError(error.jqXHR.responseJSON.message);
      }
      throw error;
    }
    this.notificationsService.notifyConfirmation(
      this.intl.t(
        'components.settings.teams.action-buttons-component.notifications.save-new-team.confirmation-inbox',
      ),
    );
    this.app.addTeam(this.team);
    this.team.set('active', true);
    this.accordion.publicAPI.closeSection(this.defaultSectionName);
    this.accordion.publicAPI.renameSection(this.defaultSectionName, this.team.id);
  }

  revertSelectedTeam() {
    if (this.team.isNew) {
      this.args.revertNewSelectedTeam(this.team);
      this.team.destroy();
    } else {
      this.team.rollbackAttributes();
      this.teamSettings.rollbackAttributes();
      this.team.set('active', true);
    }
    this.selectedTeamStatus = 'editing';
    this.accordion.publicAPI.closeSection(this.sectionName);
  }

  get isTeamValid() {
    return this.team.name.length > 0 && this.args.teamMembershipsValid;
  }

  showErrors() {
    if (this.team.name.length <= 0) {
      this.notificationsService.notifyError(
        this.intl.t(
          'components.settings.teams.action-buttons-component.notifications.errors.no-team-name-inbox',
        ),
      );
      return false;
    }

    if (!this.args.teamMembershipsValid) {
      this.notificationsService.notifyError(
        this.intl.t(
          'components.settings.teams.action-buttons-component.notifications.errors.no-teammate',
        ),
      );
      return false;
    }
  }

  get isPrimaryCTATooltipDisabled() {
    return this.isSaving || this.isTeamValid;
  }

  get isPrimaryCTADisabled() {
    return this.isSaving || !this.isTeamValid;
  }

  @action
  async saveTeamChanges() {
    if (!this.isTeamValid) {
      this.showErrors();
      return false;
    }

    this.isSaving = true;

    let saveTask = this.team.isNew ? this.saveNewTeam : this.saveExistingTeam;
    try {
      await saveTask();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'saved',
        object: this.team,
        assignmentMethod: this.teamSettings.distributionMethod,
      });

      this.app.updateLocalCache(this.modelDataCacheService);
      this.selectedTeamStatus = 'editing';
      if (this.guideLibraryService.canUseGuideLibraryService) {
        await this.guideLibraryService.markStepCompleted('display_only_create_your_teams');
      }

      this.isSaving = false;
    } catch (response) {
      this.notificationsService.notifyError(
        extractResponseError(response) ||
          this.intl.t(
            'components.settings.teams.action-buttons-component.notifications.errors.generic',
          ),
      );
      this.isSaving = false;
    }
  }

  @action
  deleteSelectedTeam() {
    this.args.deleteSelectedTeam(this.team);
  }

  @action
  async cancelChanges() {
    if (this.shouldDisplayDirtyConfirmation) {
      let isConfirmed = await this.intercomConfirmService.confirm(this.confirmOptions);
      if (!isConfirmed) {
        return;
      }
      this.args.clearMemberSelections();
      this.revertSelectedTeam();
    } else {
      this.args.clearMemberSelections();
      this.revertSelectedTeam();
    }
  }
}
