/* import __COLOCATED_TEMPLATE__ from './fin-identity.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { type PulseAccordion } from 'glint/pulse';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  accordion: PulseAccordion;
}

export default class StandaloneZendeskFinIdentity extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked newAgentId?: number;

  @action openSectionChanged() {
    this.newAgentId = undefined;
  }

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig!;
  }

  get ticketsSetupData() {
    return this.finStandaloneService.zendeskTicketsSetupData;
  }

  get isLoading() {
    return this.finStandaloneService.isLoadingZendeskTicketsSetupData && !this.adminOptions.length;
  }

  get adminOptions() {
    let admins = this.ticketsSetupData.admins?.map((admin) => ({
      text: admin.name,
      value: admin.id,
    }));
    return admins || [];
  }

  get selectedAgentName(): string | undefined {
    if (this.isLoading) {
      return undefined;
    }

    return this.adminOptions.find((option) => option.value === this.selectedAgentId)?.text;
  }

  // Pulse treats null as a real value and errors if there isn't an associated selection
  // This returns undefined in the case where there is no selection
  get selectedAgentId(): number | undefined {
    if (this.zendeskConfig.ticketsIntegration.finZendeskUserId) {
      return this.zendeskConfig.ticketsIntegration.finZendeskUserId;
    }
    return undefined;
  }

  @action setAgentId(agentId: number) {
    this.newAgentId = agentId;
  }

  @action async updateAgentId() {
    if (!this.newAgentId) {
      this.notificationsService.notifyInfo(
        this.intl.t('standalone.zendesk.deploy.tickets.fin-identity.choose-an-agent-first'),
      );
      return;
    }

    try {
      await this.zendeskConfig.setFinUser(this.newAgentId);

      this.notificationsService.notifyConfirmation(
        this.intl.t('standalone.zendesk.deploy.tickets.fin-identity.agent-set-success', {
          agentName: this.selectedAgentName,
        }),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t('standalone.zendesk.deploy.tickets.fin-identity.error-setting-agent'),
      });
      console.error(e);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::Tickets::FinIdentity': typeof StandaloneZendeskFinIdentity;
    'standalone/zendesk/deploy/tickets/fin-identity': typeof StandaloneZendeskFinIdentity;
  }
}
