/* import __COLOCATED_TEMPLATE__ from './sunshine-api.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { RESPONSE_PROVIDED_ERRORS } from 'embercom/objects/standalone/constants';
import type IntlService from 'ember-intl/services/intl';
import type SunshineConfiguration from 'embercom/models/standalone/sunshine-configuration';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';

export interface Args {}

export default class SunshineApi extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare intl: IntlService;
  @service declare notificationsService: {
    notifyConfirmation: (message: string) => void;
    notifyResponseError: (
      error: Error,
      messages: { default: string },
      options: { responseProvidedErrors: number[] },
    ) => void;
  };

  @tracked isRevokingAccess = false;
  @tracked isSaving = false;

  get isDisconnected() {
    return !this.sunshineConfig || this.sunshineConfig.isSunshineDisconnected;
  }

  @action async save() {
    this.isSaving = true;
    try {
      await this.finStandaloneService.saveZendeskConfig();
    } finally {
      await this.finStandaloneService.zendeskConfig!.reload();
      this.isSaving = false;
    }
  }

  @action async revokeAccess() {
    let isConfirmed = await this.intercomConfirmService.confirm({
      body: this.intl.t('standalone.zendesk.settings.sunshine-api.confirm-body'),
      confirmButtonText: this.intl.t('standalone.zendesk.settings.sunshine-api.confirm-button'),
    });

    try {
      if (isConfirmed) {
        this.isRevokingAccess = true;
        await this.zendeskConfig.revokeSunshine();
        this.notificationsService.notifyConfirmation(
          this.intl.t('standalone.zendesk.settings.sunshine-api.revoke-success-notification'),
        );
      }
    } catch (e) {
      console.error(e.message || e.jqXHR);
      this.notificationsService.notifyResponseError(
        e,
        {
          default: this.intl.t(
            'standalone.zendesk.settings.sunshine-api.revoke-failure-notification',
          ),
        },
        {
          responseProvidedErrors: RESPONSE_PROVIDED_ERRORS,
        },
      );
    } finally {
      this.isRevokingAccess = false;
    }
  }

  get sunshineConfig(): SunshineConfiguration {
    return this.finStandaloneService.zendeskConfig!.sunshineIntegration;
  }

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig!;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Settings::SunshineApi': typeof SunshineApi;
    'standalone/zendesk/settings/sunshine-api': typeof SunshineApi;
  }
}
