/* import __COLOCATED_TEMPLATE__ from './teammate-list-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/require-computed-property-dependencies */
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import TeammateListBaseComponent from 'embercom/components/settings/teams/teammate-list-base-component';
import { union, sort, and } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import ENV from 'embercom/config/environment';
import { later, next } from '@ember/runloop';
import { debounce } from '@ember/runloop';
import resolveObjectPath from 'embercom/lib/resolve-object-path';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import { findBy } from '@intercom/pulse/lib/computed-properties';
import TeammatesCsvExporter from 'embercom/lib/teammates/csv-exporter';
import { A } from '@ember/array';
import { isPresent } from '@ember/utils';

const NO_FILTER_VALUE = null;
const WITHOUT_ROLE_FILTER_VALUE = 'role:null';
const WITH_INBOX_SEAT_FILTER_VALUE = 'inbox_seat:true';
const WITHOUT_INBOX_SEAT_FILTER_VALUE = 'inbox_seat:false';
const WITH_UNLIMITED_COPILOT_ACCESS = 'copilot_unlimited_access:true';
const WITH_INCLUDED_COPILOT_ACCESS = 'copilot_limited_access:true';
const WITH_COPILOT_ACCESS_OFF = 'copilot_access_off:false';
const FILTER_ATTRIBUTE_MAP = {
  role: 'currentAppPermissions.role_id',
  inbox_seat: 'currentAppPermissions.has_inbox_access',
  copilot_unlimited_access: 'hasUnlimitedCopilotAccess',
  copilot_limited_access: 'hasLimitedCopilotAccess',
  copilot_access_off: 'canInboxAccessCopilot',
};
const LAST_ACTIVE_SORTING_PROP_KEY = 'last_active';

export default TeammateListBaseComponent.extend({
  router: service(),
  store: service(),
  appService: service(),
  customerService: service(),
  permissionsMutatorService: service(),
  csv: service(),
  intl: service(),
  copilotUsageService: service(),

  adminsWithInboxAccessCount: readOnly('permissionsMutatorService.adminsWithInboxAccessCount'),
  classNames: ['o__with-empty-last-row'],
  classNameBindings: ['selectedTeammates.length:o__has-selected-teammates'],
  tableIsVisible: true,
  teammates: null,
  roles: null,
  currentSortColumn: findBy('columns', 'isSortColumn', true),
  searchTerm: '',
  debouncedSearchTerm: '',
  selectedFilter: NO_FILTER_VALUE,
  selectedFilterType: null,
  selectedFilterValue: null,
  selectedTeammateIds: null,
  newSettings: false,
  selectedActiveDateFilterType: 'for',
  selectedTeammates: computed('teammatesAndTeams.@each.isSelected', function () {
    return this.teammatesAndTeams.filter((r) => r.isSelected).map((r) => r.teammate);
  }),
  showInboxSeatColumn: and('app.inboxIsActive', 'app.requiresInboxSeatAccess'),
  filteredTeammates: computed('debouncedSearchTerm', 'lastActiveTimestamp', function () {
    let filteredTeammates = this.teammates;
    if (this.lastActiveTimestamp) {
      filteredTeammates = filteredTeammates.filter((teammate) => {
        return !teammate.last_active || this.lastActiveTimestamp > teammate.last_active;
      });
    }
    if (this.debouncedSearchTerm) {
      let downcasedSearchTerm = this.debouncedSearchTerm.toLocaleLowerCase();
      filteredTeammates = filteredTeammates.filter((teammate) => {
        return (
          teammate.name.toLocaleLowerCase().includes(downcasedSearchTerm) ||
          teammate.email.includes(downcasedSearchTerm)
        );
      });
    }

    return filteredTeammates;
  }),
  teammatesWithFilter: computed(
    'filteredTeammates',
    'selectedFilterType',
    'selectedFilterValue',
    function () {
      if (this.selectedFilterType === NO_FILTER_VALUE || !this.selectedFilterType) {
        return this.filteredTeammates;
      }

      return this.filteredTeammates.filter(
        (teammate) =>
          (resolveObjectPath(teammate, FILTER_ATTRIBUTE_MAP[this.selectedFilterType])?.toString() ||
            'null') === this.selectedFilterValue,
      );
    },
  ),

  teammatesAndTeams: computed(
    'teammatesWithFilter',
    'app.teamsWithoutAppTeam.@each.member_ids',
    'currentSortColumn.{sortedAscending,sortingPropertyKey}',
    'selectedTeammateIds.[]',
    function () {
      let teammates = this.teammatesWithFilter;

      if (this.currentSortColumn) {
        teammates = teammates.sortBy(this.currentSortColumn.sortingPropertyKey);
        if (!this.currentSortColumn.sortedAscending) {
          teammates.reverse();
        }
      }

      return teammates.map((teammate) => ({
        teammate,
        teammateTeams: this.get('app.teamsWithoutAppTeam').filter((team) =>
          team.get('member_ids').includes(parseInt(teammate.get('id'), 10)),
        ),
        roles: this.roles,
        isCurrentAdmin: this.app.currentAdmin === teammate,
        isSelected:
          this.selectedTeammateIds.includes(teammate.id) && this.app.currentAdmin !== teammate,
      }));
    },
  ),
  allTeammatesSelected: computed('teammatesAndTeams.@each.isSelected', function () {
    return this.teammatesAndTeams.every(
      (row) => row.isSelected || this.app.currentAdmin === row.teammate,
    );
  }),

  rolesSortBy: ['name:asc'],
  sortedRoles: sort('roles', 'rolesSortBy'),
  filterItems: computed('intl.locale', 'sortedRoles.[]', 'showInboxSeatColumn', function () {
    let filter_items = [
      {
        items: [
          { text: this.intl.t('settings.teammate-list.everyone'), value: NO_FILTER_VALUE },
          { text: this.intl.t('settings.teammate-list.no-role'), value: WITHOUT_ROLE_FILTER_VALUE },
        ],
      },
      {
        heading: this.intl.t('settings.teammate-list.roles'),
        items: this.sortedRoles.map((role) => {
          return { text: role.name, value: `role:${role.id}` };
        }),
      },
    ];

    if (this.showInboxSeatColumn) {
      filter_items.insertAt(1, {
        heading: this.intl.t('settings.teammate-list.inbox-seats'),
        items: [
          {
            text: this.intl.t('settings.teammate-list.seat-on'),
            value: WITH_INBOX_SEAT_FILTER_VALUE,
          },
          {
            text: this.intl.t('settings.teammate-list.seat-off'),
            value: WITHOUT_INBOX_SEAT_FILTER_VALUE,
          },
        ],
      });
    }

    if (this.appService.app.canUseFinAiCopilotAddon && this.appService.app.hasCopilotEnabled) {
      filter_items.insertAt(1, {
        heading: this.intl.t('settings.teammate-list.fin-copilot'),
        items: [
          {
            text: this.intl.t('settings.teammate-list.unlimited-access'),
            value: WITH_UNLIMITED_COPILOT_ACCESS,
          },
          {
            text: this.intl.t('settings.teammate-list.included-access'),
            value: WITH_INCLUDED_COPILOT_ACCESS,
          },
          {
            text: this.intl.t('settings.teammate-list.access-off'),
            value: WITH_COPILOT_ACCESS_OFF,
          },
        ],
      });
    }

    // move no-role option under the "Roles" heading
    if (
      (this.appService.app.canUseFinAiCopilotAddon && this.appService.app.hasCopilotEnabled) ||
      this.showInboxSeatColumn
    ) {
      let noRoleIndex =
        this.appService.app.canUseFinAiCopilotAddon &&
        this.appService.app.hasCopilotEnabled &&
        this.showInboxSeatColumn
          ? 3
          : 2;

      filter_items[0]['items'].removeAt(1);
      filter_items[noRoleIndex]['items'].insertAt(0, {
        text: this.intl.t('settings.teammate-list.no-role'),
        value: WITHOUT_ROLE_FILTER_VALUE,
      });
    }

    return filter_items;
  }),

  contractedInboxSeatCount: computed(
    'app.isSalesforceContracted',
    'customerService.isSelfServeAnnualCustomer',
    'freeSeatCount',
    function () {
      if (this.app.isSalesforceContracted || this.customerService.isSelfServeAnnualCustomer) {
        return this.freeSeatCount;
      }
    },
  ),

  inboxSeatTooltipText: computed(
    'intl.locale',
    'inboxSeatCount',
    'contractedInboxSeatCount',
    function () {
      let toolTipText = '';
      if (this.get('app.customer.currentEarlyStageCustomer') || !this.showInboxSeatColumn) {
        return toolTipText;
      }

      if (this.get('app.isOnFree')) {
        toolTipText = this.intl.t('settings.teammate-list.free-plan-inbox-seat-tooltip');
      } else {
        toolTipText = this.contractedInboxSeatCount
          ? this.intl.t('settings.teammate-list.contracted-inbox-seat-tooltip', {
              pricePerSeat: this.pricePerSeat,
              contractedInboxSeatCount: this.contractedInboxSeatCount,
              inboxSeatCount: this.inboxSeatCount,
            })
          : this.intl.t('settings.teammate-list.base-plan-inbox-seat-tooltip', {
              pricePerSeat: this.pricePerSeat,
              inboxSeatCount: this.inboxSeatCount,
            });
      }

      return toolTipText;
    },
  ),
  inboxSeatCount: computed('adminsWithInboxAccessCount', function () {
    return this.teammates.reduce(
      (count, teammate) => count + (teammate.currentAppPermissions?.has_inbox_access ? 1 : 0),
      0,
    );
  }),
  teammatesAndTeamsAndEmptyRow: union('teammatesAndTeams', 'emptyRow'),
  columns: computed(
    'intl.locale',
    'app.{inboxIsActive,customer.currentEarlyStageCustomer,isSalesforceContracted}',
    'inboxSeatCount',
    function () {
      let columns = [];
      columns.push(
        this.createColumn(
          this.intl.t('settings.teammate-list.name'),
          true,
          'table-cells/teammate-list/name-cell-wrapper',
          '',
          true,
          true,
          true,
          'name',
        ),
      );
      if (!this.appService.app.canUseStandalone) {
        columns.push(
          this.createColumn(
            this.intl.t('settings.teammate-list.status'),
            true,
            'table-cells/teammate-list/status-cell-wrapper',
          ),
        );

        if (this.showInboxSeatColumn) {
          let headerText = `<span data-test-inbox-seat-header>${this.intl.t(
            'settings.teammate-list.inbox-seats-used',
            { inboxSeatCount: this.inboxSeatCount },
          )}</span>`;

          if (this.app.isSalesforceContracted && this.app.canUseSeatOverages) {
            columns.push(
              this.createColumn(
                sanitizeHtml(headerText),
                true,
                'table-cells/teammate-list/inbox-seat-cell',
                {
                  component: 'table-cells/teammate-list/inbox-seat-column-tooltip-with-opener',
                  data: {
                    contractedInboxSeatCount: this.contractedInboxSeatCount,
                    inboxSeatCount: this.inboxSeatCount,
                  },
                },
              ),
            );
          } else {
            columns.push(
              this.createColumn(
                sanitizeHtml(headerText),
                true,
                'table-cells/teammate-list/inbox-seat-cell',
                this.inboxSeatTooltipText,
              ),
            );
          }
        }
        if (this.appService.app.canUseFinAiCopilotAddon) {
          columns.push(
            this.createColumn(
              this.intl.t('settings.teammate-list.fin-ai-copilot'),
              false,
              'table-cells/teammate-list/copilot-access-cell-wrapper',
              {
                component: 'settings/copilot/copilot-usage-tooltip',
              },
              false,
              false,
              false,
              undefined,
              'copilot',
            ),
          );
        }

        if (this.get('app.inboxIsActive')) {
          columns.push(
            this.createColumn(
              this.intl.t('settings.teammate-list.teams'),
              true,
              'table-cells/teammate-list/teams-cell',
            ),
          );
        }
      }

      if (
        this.appService.app.canUseStandalone &&
        this.appService.app.canUseStandaloneLimitedAdminAccess &&
        isPresent(this.roles)
      ) {
        columns.push(
          this.createColumn(
            this.intl.t('settings.teammate-list.permission'),
            true,
            'table-cells/teammate-list/standalone-access-level-selector',
          ),
        );
      } else {
        columns.push(
          this.createColumn(
            this.intl.t('settings.teammate-list.permission'),
            true,
            'table-cells/teammate-list/permission-cell-wrapper',
          ),
        );
      }
      if (!this.appService.app.canUseStandalone) {
        if (this.appService.app.canSeeLastSeenDatesOnTeammateList) {
          columns.push(
            this.createColumn(
              this.intl.t('settings.teammate-list.last-active'),
              true,
              'table-cells/teammate-list/legacy-last-seen-at-cell',
              '',
              true,
              false,
              true,
              LAST_ACTIVE_SORTING_PROP_KEY,
            ),
          );
        }
      }
      columns.push(
        this.createColumn(
          this.intl.t('settings.teammate-list.two-f-a'),
          true,
          'table-cells/teammate-list/two-f-a-cell',
        ),
      );
      columns.push(
        this.createColumn('', false, 'table-cells/teammate-list/send-recovery-code-cell'),
      );
      if (!this.appService.app.canUseStandalone) {
        columns.push(this.createColumn('', false, 'table-cells/teammate-list/edit-cell'));
      } else {
        columns.push(this.createColumn('', false, 'table-cells/teammate-list/delete-cell'));
      }
      return columns;
    },
  ),

  selectedFilters: computed('lastActiveTimestamp', 'selectedFilterType', function () {
    if (this.selectedFilterType === NO_FILTER_VALUE && !this.lastActiveTimestamp) {
      return null;
    }

    let filterList = [];

    if (this.selectedFilterType !== NO_FILTER_VALUE) {
      filterList.push(this.selectedFilterType);
    }

    if (this.lastActiveTimestamp) {
      filterList.push(LAST_ACTIVE_SORTING_PROP_KEY);
    }

    return filterList;
  }),

  init() {
    this._super(...arguments);
    if (this.selectedFilterType !== NO_FILTER_VALUE) {
      this.set('selectedFilter', `${this.selectedFilterType}:${this.selectedFilterValue}`);
    }
    this.set('emptyRow', [{ teammate: undefined, teammateTeams: undefined, roles: this.roles }]);
    this.set('selectedTeammateIds', A());

    this.loadInboxSeatInfo.perform();
    if (this.appService.app.canUseFinAiCopilotAddon && this.appService.app.hasCopilotEnabled) {
      this.copilotUsageService.refreshCopilotUsageData();
    }
  },

  assignDebouncedSearchTerm() {
    this.set('debouncedSearchTerm', this.searchTerm);
    this.preventInboxSeatToggleTransition();
  },

  preventInboxSeatToggleTransition() {
    // we need to hide the table when changing its content because the inbox seat toggle component transitions between states
    // for some reason, which makes it look like the row values are changing, not the actual rows.
    this.set('tableIsVisible', false);
    next(this, () => {
      later(
        this,
        () => {
          this.set('tableIsVisible', true);
        },
        ENV.APP._250MS,
      );
    });
  },

  actions: {
    bulkEditPermissions() {
      if (this.selectedTeammates.length === 1) {
        // There is no point in doing bulk edit permissions if only single teammate is selected,
        // lets render edit permissions page for this single admin
        this.router.transitionTo(
          'apps.app.settings.workspace.teammates.teammate.permissions',
          this.selectedTeammates.firstObject.id,
        );
      } else {
        let transition = this.router.transitionTo(
          'apps.app.settings.workspace.teammates.permissions',
        );
        transition.data.teammates = this.selectedTeammates;
      }
    },
    bulkRemoveTeammates() {
      if (this.selectedTeammates.length === 1) {
        // There is no point in doing bulk edit permissions if only single teammate is selected,
        // lets render edit permissions page for this single admin
        this.router.transitionTo(
          'apps.app.settings.workspace.teammates.teammate.remove',
          this.selectedTeammates.firstObject.id,
        );
      } else {
        let transition = this.router.transitionTo('apps.app.settings.workspace.teammates.remove');
        transition.data.teammates = this.selectedTeammates;
      }
    },
    changeFilterValue(value) {
      // we need to hide the table when changing its content because the inbox seat toggle component transitions between states
      // for some reason, which makes it look like the row values are changing, not the actual rows.
      this.set('tableIsVisible', false);
      this.set('selectedFilter', value);
      if (this.selectedFilter === NO_FILTER_VALUE) {
        this.set('selectedFilterType', null);
        this.set('selectedFilterValue', null);
      } else {
        this.set('selectedFilterType', this.selectedFilter.split(':')[0]);
        this.set('selectedFilterValue', this.selectedFilter.split(':')[1]);
      }
      later(this, () => this.set('tableIsVisible', true), ENV.APP._250MS);
    },
    changeLastActiveDateFilter(lastActiveTimestamp) {
      if (this.lastActiveTimestamp !== lastActiveTimestamp) {
        this.set('lastActiveTimestamp', lastActiveTimestamp);
        this.preventInboxSeatToggleTransition();
      }
    },
    changeSearchTerm(_value) {
      debounce(this, this.assignDebouncedSearchTerm, ENV.APP._250MS);
    },
    selectRow(row) {
      if (this.selectedTeammateIds.includes(row.teammate.id)) {
        this.selectedTeammateIds.removeObject(row.teammate.id);
      } else {
        this.selectedTeammateIds.pushObject(row.teammate.id);
      }
    },
    selectAll() {
      this.filteredTeammates.forEach((teammate) => {
        if (this.selectedTeammateIds.includes(teammate.id) || this.app.currentAdmin === teammate) {
          return;
        }

        this.selectedTeammateIds.pushObject(teammate.id);
      });
    },
    clearSelection() {
      this.filteredTeammates.forEach((teammate) => {
        this.selectedTeammateIds.removeObject(teammate.id);
      });
    },
    clearFilters() {
      this.set('selectedFilterType', null);
      this.set('selectedFilterValue', null);
      this.set('selectedFilter', NO_FILTER_VALUE);
      this.set('searchTerm', '');
      this.set('debouncedSearchTerm', '');
    },
    exportTeammates() {
      let exporter = new TeammatesCsvExporter(this.appService.app, this.teammatesWithFilter, {
        showInboxSeatColumn: this.showInboxSeatColumn,
      });

      this.csv.export(exporter.data, {
        fileName: exporter.filename,
        withSeparator: false,
      });
    },
    onSort(column) {
      let newSortColumn = this.columns.findBy('sortingPropertyKey', column.sortingPropertyKey);

      if (this.currentSortColumn) {
        if (this.currentSortColumn.get('sortingPropertyKey') === newSortColumn.sortingPropertyKey) {
          newSortColumn.toggleProperty('sortedAscending');
        } else {
          this.currentSortColumn.set('isSortColumn', false);
          newSortColumn.set('isSortColumn', true);
        }
      } else {
        newSortColumn.set('isSortColumn', true);
      }

      this.preventInboxSeatToggleTransition();
    },
  },
});
