/* import __COLOCATED_TEMPLATE__ from './case-origins.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable @intercom/intercom/no-bare-strings */

import Component from '@glimmer/component';
import { type PulseAccordion } from 'glint/pulse';
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {
  accordion: PulseAccordion;
}

export default class StandaloneSalesforceCaseOrigins extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare notificationsService: $TSFixMe;

  @tracked newlyAddedCaseOrigins: Array<string> = [];
  @tracked newlyRemovedCaseOrigins: Array<string> = [];

  get salesforceConfig() {
    return this.finStandaloneService.salesforceConfig;
  }

  get salesforceSetupData() {
    return this.finStandaloneService.salesforceSetupData;
  }

  get isLoadingSalesforceSetupData() {
    return this.finStandaloneService.isLoadingSalesforceSetupData;
  }

  get persistedSelection() {
    return this.finStandaloneService.selectedSalesforceCaseOrigins;
  }

  get allCaseOrigins() {
    return this.salesforceSetupData.caseOrigins;
  }

  get enabledCaseOrigins() {
    return [...this.persistedSelection, ...this.newlyAddedCaseOrigins]
      .uniq()
      .filter((origin) => !this.newlyRemovedCaseOrigins.includes(origin));
  }

  @action openSectionChanged() {
    this.newlyAddedCaseOrigins = [];
    this.newlyRemovedCaseOrigins = [];
  }

  @action toggleCaseOrigin(origin: string) {
    let isCurrentlyEnabled = this.persistedSelection.includes(origin);

    if (isCurrentlyEnabled) {
      let isLocallyDisabled = this.newlyRemovedCaseOrigins.some((o) => o === origin);

      if (isLocallyDisabled) {
        this.newlyRemovedCaseOrigins = this.newlyRemovedCaseOrigins.filter((o) => o !== origin);
      } else {
        this.newlyRemovedCaseOrigins.pushObject(origin);
        this.newlyAddedCaseOrigins.removeObject(origin);
      }
    } else {
      let isLocallyEnabled = this.newlyAddedCaseOrigins.some((o) => o === origin);

      if (isLocallyEnabled) {
        this.newlyAddedCaseOrigins = this.newlyAddedCaseOrigins.filter((o) => o !== origin);
      } else {
        this.newlyAddedCaseOrigins.pushObject(origin);
        this.newlyRemovedCaseOrigins.removeObject(origin);
      }
    }
  }

  @action async save() {
    try {
      this.newlyAddedCaseOrigins.forEach(async (origin) => {
        await this.finStandaloneService.addSalesforceCaseOrigin(origin);
      });

      this.newlyRemovedCaseOrigins.forEach(async (origin) => {
        await this.finStandaloneService.removeSalesforceCaseOrigin(origin);
      });

      this.notificationsService.notifyConfirmation('Case Origins updated');

      this.finStandaloneService.selectedSalesforceCaseOrigins = this.enabledCaseOrigins;
      this.newlyAddedCaseOrigins = [];
      this.newlyRemovedCaseOrigins = [];
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: "Something went wrong and we couldn't update your Case Origins",
      });
    }
  }

  get saveDisabled() {
    return (
      this.salesforceConfig?.isPendingSetup ||
      (this.newlyAddedCaseOrigins.length === 0 && this.newlyRemovedCaseOrigins.length === 0)
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Salesforce::Setup::CaseOrigins': typeof StandaloneSalesforceCaseOrigins;
    'standalone/salesforce/setup/case-origins': typeof StandaloneSalesforceCaseOrigins;
  }
}
