/* import __COLOCATED_TEMPLATE__ from './conditional-values-select.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type ListOption from 'embercom/models/conversation-attributes/list-option';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    descriptor: any;
    onSelectionChange: (options: string[]) => void;
    selectedOptions: string[];
  };
}

class DropdownItem {
  text: string;
  value: string;
  @tracked isSelected: boolean;

  constructor(data: { text: string; value: string; isSelected: boolean }) {
    this.text = data.text;
    this.value = data.value;
    this.isSelected = data.isSelected;
  }
}

export default class ConditionalValuesSelect extends Component<Signature> {
  @service declare intl: IntlService;
  @tracked dropdownOptions = this._buildDropdownOptions();
  @tracked filterText = '';

  get noOptionsSelected() {
    return this.args.selectedOptions.length === 0;
  }

  get isFilterable() {
    return this.dropdownOptions.length > 10;
  }

  get filteredDropdownOptions() {
    if (this.filterText === '') {
      return this.dropdownOptions;
    }

    return this.dropdownOptions.filter((option) => {
      return option.text.toLowerCase().includes(this.filterText.toLowerCase());
    });
  }

  @action onSelectItem(value: string) {
    if (value === '') {
      if (this.noOptionsSelected) {
        return; // user shouldn't be able to deselect all option
      }

      // deselect all selected options
      this.dropdownOptions.forEach((option: DropdownItem) => (option.isSelected = false));
      // select the 'All' option
      this.dropdownOptions[0].isSelected = true;
      this.args.onSelectionChange([]);
    } else {
      this.dropdownOptions[0].isSelected = false;
      let currentOption = this.dropdownOptions.find(
        (option: DropdownItem) => option.value === value,
      );

      let selectedOptions = this.args.selectedOptions.slice();
      if (selectedOptions.includes(value)) {
        currentOption!.isSelected = false;
        selectedOptions.removeObject(value);
      } else {
        currentOption!.isSelected = true;
        selectedOptions.push(value);
      }
      if (selectedOptions.length === 0) {
        this.dropdownOptions[0].isSelected = true;
      }
      this.args.onSelectionChange(selectedOptions);
    }
  }

  get label() {
    if (
      this.args.selectedOptions.length === 0 ||
      this.args.selectedOptions.length ===
        this.dropdownOptions.length - 1 /* Excludes all options text */
    ) {
      return this.intl.t('settings.conversation-attributes.conditions-side-drawer.all-options');
    }

    return this.dropdownOptions
      .filter((option: any) => this.args.selectedOptions.includes(option.value))
      .map((option: any) => option.text)
      .join(', ');
  }

  _buildDropdownOptions() {
    let result = [
      new DropdownItem({
        text: this.intl.t('settings.conversation-attributes.conditions-side-drawer.all-options'),
        value: '',
        isSelected: this.args.selectedOptions.length === 0,
      }),
    ];
    return result.concat(
      this.args.descriptor.listOptions.rejectBy('archived').map((option: ListOption) => {
        return new DropdownItem({
          text: option.label,
          value: option.id,
          isSelected: this.args.selectedOptions.includes(option.id),
        });
      }),
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::ConversationAttributes::ConditionalValuesSelect': typeof ConditionalValuesSelect;
    'settings/conversation-attributes/conditional-values-select': typeof ConditionalValuesSelect;
  }
}
