/* import __COLOCATED_TEMPLATE__ from './checkout-header.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';

interface Signature {
  Args: {};
  Element: never;
  Blocks: {
    default: [];
  };
}

export default class CheckoutHeader extends Component<Signature> {
  @service declare session: Session;
  @service declare appService: any;

  get app() {
    return this.appService.app;
  }

  changeLocale(newLocale: any) {
    this.session.changeLanguage(newLocale, this.app, this.app.currentAdmin);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::Components::CheckoutHeader': typeof CheckoutHeader;
  }
}
