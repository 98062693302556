/* import __COLOCATED_TEMPLATE__ from './connect.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable @intercom/intercom/no-bare-strings */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type SalesforceConfiguration from 'embercom/models/standalone/salesforce-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { action } from '@ember/object';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';

interface Args {}

export default class StandaloneSalesforceConnect extends Component<Args> {
  @service declare appService: any;
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare notificationsService: $TSFixMe;
  @service declare redirectService: any;

  get installGroupList() {
    return [
      {
        items: [
          {
            text: 'Connect to sandbox',
            icon: 'code',
            description: 'For testing Fin before you go live',
            onSelectItem: () => this.startConnectFlow(true),
          },
          {
            text: 'Connect to production',
            icon: 'salesforce',
            description: "For when you're ready to set Fin live",
            onSelectItem: () => this.startConnectFlow(false),
          },
        ],
      },
    ];
  }

  @action startConnectFlow(sandbox = false) {
    let uri = this.oauthUrls.connect;
    let params = `app_id_code=${this.appService.app.id}&sandbox=${sandbox}`;
    this.redirectService.redirect(`${uri}?${params}`);
  }

  @action async disconnectApp() {
    let isConfirmed = await this.intercomConfirmService.confirm({
      body: `Are you sure you want to disconnect from ${this.salesforceConfig.environmentLabel}? Fin will immediately stop responding to cases.`,
      confirmButtonText: 'Disconnect',
      primaryButtonType: 'primary-destructive',
    });

    try {
      if (isConfirmed) {
        await this.salesforceConfig.disconnectApp();
        this.notificationsService.notifyConfirmation('You have disconnected from Salesforce.');
      }
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: `Something went wrong and we couldn't disconnect your Salesforce app. Please try again`,
      });
    }
  }

  get oauthUrls() {
    let { protocol, host } = window.location;
    return {
      connect: `${protocol}//${host}/standalone/salesforce/connect`,
      connectCallback: `${protocol}//${host}/standalone/salesforce/connect_callback`,
    };
  }

  get salesforceConfig(): SalesforceConfiguration {
    return this.finStandaloneService.salesforceConfig!;
  }

  get connectedBannerText() {
    return this.salesforceConfig.isSandbox
      ? 'Fin is connected to your sandbox – for testing before you go live:'
      : 'Fin is connected to production:';
  }

  get disconnectButtonLabel() {
    return this.salesforceConfig.isSandbox
      ? 'Disconnect from sandbox'
      : 'Disconnect from production';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Salesforce::Setup::Connect': typeof StandaloneSalesforceConnect;
    'standalone/salesforce/setup/connect': typeof StandaloneSalesforceConnect;
  }
}
