/* import __COLOCATED_TEMPLATE__ from './activation.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable @intercom/intercom/no-bare-strings */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type SalesforceConfiguration from 'embercom/models/standalone/salesforce-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { action } from '@ember/object';
import ENV from 'embercom/config/environment';

interface Args {}

export default class StandaloneSalesforceSetupActivation extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare notificationsService: $TSFixMe;

  get salesforceConfig(): SalesforceConfiguration {
    return this.finStandaloneService.salesforceConfig!;
  }

  @action async activateFinForSalesforce() {
    try {
      await this.salesforceConfig.activateForCases();
      this.notifyConfirmation('Fin has been enabled for Salesforce cases');
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: `Something went wrong and we couldn't activate Fin. Please try again`,
      });
      console.error(e);
    }
  }

  @action async deactivateFinForSalesforce() {
    try {
      await this.salesforceConfig.deactivateForCases();
      this.notifyInfo('Fin has been disabled for Salesforce cases');
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: `Something went wrong and we couldn't deactivate Fin. Please try again`,
      });
    }
  }

  private notifyConfirmation(message: string) {
    this.notificationsService.notifyConfirmation(
      message,
      ENV.APP._2000MS,
      'fin-standalone-activation',
    );
  }

  private notifyInfo(message: string) {
    this.notificationsService.notify(message, ENV.APP._2000MS, 'fin-standalone-activation');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Salesforce::Setup::Activation': typeof StandaloneSalesforceSetupActivation;
  }
}
