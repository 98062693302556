/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import type { OrganizationField } from 'embercom/objects/standalone/data-fields/common';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { StandaloneCompanyAttribute } from 'embercom/objects/standalone/data-fields/common';

interface Args {
  archivedAttributes: any[];
}

export default class StandaloneCompanyAttributesTableComponent extends Component<Args> {
  @service declare appService: any;
  @service declare attributeService: any;
  @service declare finStandaloneService: FinStandaloneService;
  @service declare modalService: any;

  @tracked filterSearchTerm = '';

  get filteredAttributes() {
    return this.sortedAttributes.filter((attribute: StandaloneCompanyAttribute) =>
      attribute.name.toLowerCase().includes(this.filterSearchTerm.toLowerCase()),
    );
  }

  private get sortedAttributes() {
    return this.attributes.sort((a: StandaloneCompanyAttribute, b: StandaloneCompanyAttribute) =>
      a.name.localeCompare(b.name),
    );
  }

  private get attributes() {
    return this.unmappedStandaloneCustomCompanyAttributes
      .map(
        (attributeWithSettings: any) =>
          new StandaloneCompanyAttribute({
            intercomAttribute: attributeWithSettings.attribute,
          }),
      )
      .concat(
        this.standaloneSyncedCompanyAttributes.map(
          (attribute: OrganizationField) =>
            new StandaloneCompanyAttribute({
              standalonePlatform: 'zendesk',
              organizationField: attribute,
              intercomAttribute: this.attributeService.displayableCompanyAttributes.find(
                (attributeWithSettings: any) =>
                  attributeWithSettings.attribute.identifier === attribute.intercom_cda?.identifier,
              ).attribute,
            }),
        ),
        this.standaloneRequiredCompanyAttributes.map(
          (attribute: OrganizationField) =>
            new StandaloneCompanyAttribute({
              standalonePlatform: 'zendesk',
              organizationField: attribute,
            }),
        ),
      );
  }

  private get unmappedStandaloneCustomCompanyAttributes() {
    return this.attributeService.displayableCompanyAttributes
      .filter(
        (attributeWithSettings: any) =>
          attributeWithSettings.attribute.isCustomData &&
          !this.standaloneSyncedCompanyAttributes.any(
            (attribute) =>
              attribute.intercom_cda?.identifier === attributeWithSettings.attribute.identifier,
          ),
      )
      .concat(this.archivedCompanyAttributes);
  }

  private get standaloneSyncedCompanyAttributes() {
    return this.standalonePlatformCompanyAttributes.filter((field) =>
      isPresent(field.intercom_cda?.identifier),
    );
  }

  private get standaloneRequiredCompanyAttributes() {
    return this.standalonePlatformCompanyAttributes.filter((field) => field.required);
  }

  private get standalonePlatformCompanyAttributes() {
    if (this.appService.app.hasStandalonePlatform('zendesk')) {
      return this.finStandaloneService.zendeskDataFields.organizationFields;
    } else if (this.appService.app.hasStandalonePlatform('salesforce')) {
      // we currently don't sync company attributes to salesforce
      return [];
    }

    return [];
  }

  private get archivedCompanyAttributes() {
    return this.archivedAttributesWithSettings.filterBy('attribute.isCompany');
  }

  private get archivedAttributesWithSettings() {
    return this.attributeService.attributesWithSettings(this.args.archivedAttributes);
  }

  @action
  openDetailsView(attribute: any) {
    if (attribute) {
      this.modalService.openModal('settings/modals/attribute-details', {
        attribute,
        allowArchival: attribute.isCustomData,
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::CompanyAttributes::Table': typeof StandaloneCompanyAttributesTableComponent;
    'standalone::company-attributes::table': typeof StandaloneCompanyAttributesTableComponent;
  }
}
