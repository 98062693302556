/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import templateOnlyComponent from '@ember/component/template-only';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export interface Args {
  title: string;
  description: string;
  statusClasses: string;
  statusIcon: InterfaceIconName;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

const Card = templateOnlyComponent<Signature>();
export default Card;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Deploy::Content::Card': typeof Card;
    'standalone::deploy::content::card': typeof Card;
  }
}
