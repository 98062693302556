/* import __COLOCATED_TEMPLATE__ from './block-customer-from-checkout.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class BlockCustomerFromCheckout extends Component {
  @service declare intl: any;
  @service declare purchaseAnalyticsService: any;
  @service declare customerService: any;

  @action
  onBackButtonClick() {
    window.history.back();
  }

  @action
  openIntercomWidget() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'block_local_customers',
      place: 'checkout',
      object: 'contact_support',
    });

    window.Intercom('show');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::Checkouts::BlockCustomerFromCheckout': typeof BlockCustomerFromCheckout;
  }
}
