/* import __COLOCATED_TEMPLATE__ from './activation.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import ENV from 'embercom/config/environment';
import { RESPONSE_PROVIDED_ERRORS } from 'embercom/objects/standalone/constants';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type IntlService from 'ember-intl/services/intl';

interface Args {}

export default class SunshineActivation extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intl: IntlService;
  @service declare notificationsService: {
    notifyConfirmation: (message: string, duration: number, key: string) => void;
    notifyResponseError: (
      e: Error,
      messages: Record<string, string>,
      options: { responseProvidedErrors: number[] },
    ) => void;
  };

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig!;
  }

  @action async activateFinForMessenger() {
    try {
      await this.zendeskConfig.activateForMessenger();
      this.notifyConfirmation(
        this.intl.t('standalone.zendesk.deploy.sunshine.activation.notify-activate-success'),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(
        e,
        {
          default: this.intl.t(
            'standalone.zendesk.deploy.sunshine.activation.notify-activate-error',
          ),
        },
        {
          responseProvidedErrors: RESPONSE_PROVIDED_ERRORS,
        },
      );
      console.error(e.message || e.jqXHR);
    }
  }

  @action async deactivateFinForMessenger() {
    try {
      await this.zendeskConfig.deactivateForMessenger();
      this.notifyConfirmation(
        this.intl.t('standalone.zendesk.deploy.sunshine.activation.notify-deactivate-success'),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(
        e,
        {
          default: this.intl.t(
            'standalone.zendesk.deploy.sunshine.activation.notify-deactivate-error',
          ),
        },
        {
          responseProvidedErrors: RESPONSE_PROVIDED_ERRORS,
        },
      );
      console.error(e.message || e.jqXHR);
    }
  }

  notifyConfirmation(message: string) {
    this.notificationsService.notifyConfirmation(
      message,
      ENV.APP._2000MS,
      'fin-standalone-activation',
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::Sunshine::Activation': typeof SunshineActivation;
    'standalone/zendesk/deploy/sunshine/activation': typeof SunshineActivation;
  }
}
