/* import __COLOCATED_TEMPLATE__ from './connect-zendesk-apis.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import templateOnly from '@ember/component/template-only';
import { type PulseAccordion } from 'glint/pulse';

export interface Args {
  accordion: PulseAccordion;
  hideSunshine?: boolean;
}

let ConnectZendeskApis = templateOnly<Args>();

export default ConnectZendeskApis;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::ConnectZendeskApis': typeof ConnectZendeskApis;
    'standalone/zendesk/deploy/connect-zendesk-apis': typeof ConnectZendeskApis;
  }
}
