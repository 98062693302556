/* import __COLOCATED_TEMPLATE__ from './create-sender-email-address.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { isValidEmail } from 'embercom/lib/email';
import { isEmpty } from '@ember/utils';
import ajax from 'embercom/lib/ajax';
import resendSenderEmailVerificationLink from 'embercom/lib/settings/resend-sender-email-verification-link';
import { request } from 'embercom/lib/inbox/requests';

export default class CreateCustomSenderAddress extends Component {
  @service intercomEventService;
  @service notificationsService;
  @service router;
  @service store;
  @service appService;
  @service intl;

  @tracked senderName = '';
  @tracked senderAddress = '';
  @tracked showSenderCreationStep = true;
  @tracked showSenderVerificationStep = false;
  @tracked createdAddressId = null;

  @tracked brands;
  @tracked brandsDropDownFormat;
  @tracked selectedBrand = this.appService.app.name;
  @tracked selectedBrandId = this.DEFAULT_BRAND_ID;

  DEFAULT_BRAND_ID = null;

  constructor(owner, args) {
    super(owner, args);
    if (this.appService.app.canUseMultiBrandEmailOpenBeta) {
      this.initializeBrands();
    }
  }

  get disableCreateButton() {
    let emailOrNameValueEmpty = isEmpty(this.senderName) || isEmpty(this.senderAddress);
    let emailIsInvalid = !isValidEmail(this.senderAddress);
    return emailOrNameValueEmpty || emailIsInvalid;
  }

  @action
  async addNewSender() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'create_custom_sender_address',
      context: 'create_button',
    });
    try {
      let data = {
        name: this.senderName,
        email: this.senderAddress,
        app_id: this.appService.app.id,
        admin_id: this.appService.app.currentAdmin.id,
      };

      if (this.selectedBrandId !== null) {
        data.brand_id = this.selectedBrandId;
      }

      let response = await ajax({
        url: '/ember/sender_emails/create_address_and_dkim_settings',
        type: 'POST',
        data: JSON.stringify(data),
      });
      this.store.push(this.store.normalize('dkim-settings', response.dkim_settings));
      this.store.pushPayload({
        'custom-bounce-settings': response.custom_bounce_settings,
        'custom-email-address': response.sender_address,
      });
      this.createdAddressId = response.sender_address.id;
      this.showSenderCreationStep = false;
      this.showSenderVerificationStep = true;
      this.args.onAddressCreated?.();
    } catch (e) {
      if (e.jqXHR.responseJSON && e.jqXHR.responseJSON.errors) {
        this.notificationsService.notifyError(e.jqXHR.responseJSON.errors[0].message);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('settings.senders.email-address-create-button.email-not-found'),
        );
      }
    }
  }

  @action
  closeCreateCustomSender() {
    this.senderName = '';
    this.senderAddress = '';
    this.selectedBrandId = this.DEFAULT_BRAND_ID;
    this.selectedBrand = this.appService.app.name;
    this.createdAddressId = null;
    this.args.toggleDisplayCustomSenderModal();
    this.showSenderCreationStep = true;
    this.showSenderVerificationStep = false;
  }

  @action
  cancelButton() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'create_custom_sender_address',
      context: 'cancel_button',
    });
    this.closeCreateCustomSender();
  }

  @action
  resendVerificationLink() {
    resendSenderEmailVerificationLink(this.createdAddressId);
  }

  async initializeBrands() {
    this.brands = await this.loadBrands();
    this.brandsDropDownFormat = this.buildDropDownData();
  }

  async loadBrands() {
    let response = await request(`/ember/brands?app_id=${this.appService.app.id}`).then((r) =>
      r.json(),
    );
    return response;
  }

  buildDropDownData() {
    let dropDownData = this.brands.map((brand) => {
      return {
        text: brand.name,
        value: brand.id,
      };
    });

    let dropDownDataWithDefaultBrand = [
      {
        text: this.appService.app.name,
        value: this.DEFAULT_BRAND_ID,
      },
      ...dropDownData,
    ];
    let grouplist = [
      {
        items: dropDownDataWithDefaultBrand,
      },
    ];

    return grouplist;
  }

  @action
  setSelectedBrand(brand_id) {
    this.brands.forEach((brand) => {
      if (brand.id === brand_id) {
        this.selectedBrand = brand.name;
        this.selectedBrandId = brand.id;
      }
    });
  }
}
