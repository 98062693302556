/* import __COLOCATED_TEMPLATE__ from './channels.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { use } from 'ember-resources/util/function-resource';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { inject as service } from '@ember/service';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import { type PulseAccordion } from 'glint/pulse';
import { tracked } from '@glimmer/tracking';
import { type SunshineChannel, type ZendeskBrand } from 'embercom/objects/standalone/zendesk';

interface Args {
  accordion: PulseAccordion;
}

type SunshineChannelWithBrand = Pick<SunshineChannel, 'id' | 'defaultResponder'> & {
  name: string;
  brand: ZendeskBrand;
};

export default class SunshineChannels extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig!;
  }

  @tracked newlyEnabledChannels: Array<SunshineChannelWithBrand> = [];
  @tracked newlyDisabledChannels: Array<SunshineChannelWithBrand> = [];

  get enabledChannelIds() {
    let newlyDisabledChannelIds = this.newlyDisabledChannels.map((channel) => channel.id);

    return [
      ...this.zendeskConfig.sunshineIntegration.supportedChannelIntegrationIds,
      ...this.newlyEnabledChannels.map((channel) => channel.id),
    ]
      .uniq()
      .filter((id) => !newlyDisabledChannelIds.includes(id));
  }

  @use channels = AsyncData<Array<SunshineChannelWithBrand>>(async () => {
    // Once Sunshine is connected, this resource will rerun and load the channels.
    if (this.zendeskConfig.sunshineIntegration.isSunshineDisconnected) {
      return [];
    }

    let sunshineChannels = this.finStandaloneService.sunshineChannels;
    let zendeskBrands = this.finStandaloneService.zendeskBrands;

    let result = sunshineChannels.map((channel) => {
      let brand = zendeskBrands.find((brand) => String(brand.id) === String(channel.brandId));
      return {
        id: channel.id,
        name: channel.displayName,
        defaultResponder: channel.defaultResponder,
        brand,
      };
    });

    return result;
  });

  get persistedEnabledChannels() {
    if (this.channels.isLoading) {
      return [];
    }

    return this.channels.value!.filter((channel) =>
      this.zendeskConfig.sunshineIntegration.supportedChannelIntegrationIds.includes(channel.id),
    );
  }

  @action openSectionChanged() {
    this.newlyEnabledChannels = [];
    this.newlyDisabledChannels = [];
  }

  @action toggleChannel(channel: SunshineChannelWithBrand) {
    let isCurrentlyEnabled =
      this.zendeskConfig.sunshineIntegration.supportedChannelIntegrationIds.includes(channel.id);

    if (isCurrentlyEnabled) {
      let isLocallyDisabled = this.newlyDisabledChannels.some((c) => c.id === channel.id);

      if (isLocallyDisabled) {
        this.newlyDisabledChannels = this.newlyDisabledChannels.filter((c) => c.id !== channel.id);
      } else {
        this.newlyDisabledChannels.pushObject(channel);
        this.newlyEnabledChannels.removeObject(channel);
      }
    } else {
      let isLocallyEnabled = this.newlyEnabledChannels.some((c) => c.id === channel.id);

      if (isLocallyEnabled) {
        this.newlyEnabledChannels = this.newlyEnabledChannels.filter((c) => c.id !== channel.id);
      } else {
        this.newlyEnabledChannels.pushObject(channel);
        this.newlyDisabledChannels.removeObject(channel);
      }
    }
  }

  @action async save() {
    try {
      this.newlyEnabledChannels.forEach(async (channel) => {
        await this.zendeskConfig.sunshineIntegration.addFinToChannel(channel.id, channel.brand.id);
      });

      this.newlyDisabledChannels.forEach(async (channel) => {
        await this.zendeskConfig.sunshineIntegration.removeFinFromChannel(channel.id);
      });

      this.notificationsService.notifyConfirmation(
        this.intl.t('standalone.zendesk.deploy.sunshine.channels.notifications.success'),
      );

      this.newlyEnabledChannels = [];
      this.newlyDisabledChannels = [];
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('standalone.zendesk.deploy.sunshine.channels.notifications.error'),
      });
      console.error(error.message || error.jqXHR);
    }
  }

  get saveDisabled() {
    return (
      this.zendeskConfig.sunshineIntegration.isSunshineDisconnected ||
      this.zendeskConfig.sunshineIntegration.isEnabledForSunshineMessenger ||
      (this.newlyEnabledChannels.length === 0 && this.newlyDisabledChannels.length === 0)
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::Sunshine::Channels': typeof SunshineChannels;
    'standalone/zendesk/deploy/sunshine/channels': typeof SunshineChannels;
  }
}
