/* import __COLOCATED_TEMPLATE__ from './schedules.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type OfficeHoursSchedule from 'embercom/models/office-hours-schedule';

interface Args {}

export default class StandaloneZendeskSchedules extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;

  get isLoadingZendeskSchedules() {
    return this.finStandaloneService.isLoadingZendeskSchedules;
  }

  get zendeskConfig() {
    return this.finStandaloneService.zendeskConfig;
  }

  get zendeskSchedules(): Array<OfficeHoursSchedule> {
    return this.finStandaloneService.zendeskSchedules ?? [];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Settings::Schedules': typeof StandaloneZendeskSchedules;
    'standalone/zendesk/settings/schedules': typeof StandaloneZendeskSchedules;
  }
}
