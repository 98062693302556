/* import __COLOCATED_TEMPLATE__ from './external-link-shield.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
//@ts-ignore
import { sanitizeUrl } from '@intercom/pulse/lib/sanitize';

interface Args {
  linkURL: string;
  linkDomain: string;
  malicious: boolean;
  blocked: boolean;
  appId?: string;
  closeTab: () => void;
}

export default class ExternalLinkShield extends Component<Args> {
  @service declare permissionsService: any;
  @service declare intercomEventService: any;
  @service('browser/window') declare windowService: Window;

  @tracked acceptRiskIsChecked = false;

  get isOpenMaliciousURLEventDisabled() {
    return !this.acceptRiskIsChecked;
  }

  get isMaliciousLink() {
    return this.args.malicious;
  }

  get isBlockedLink() {
    return this.args.blocked;
  }

  @action
  handleClick(event: PointerEvent) {
    let permission = 'can_access_workspace_settings';
    if (!this.permissionsService.currentAdminCan(permission)) {
      event.preventDefault();
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(permission);
    }
  }

  @action
  trackGoBackEvent() {
    this.intercomEventService.trackAnalyticsEvent(
      {
        action: 'clicked',
        object: 'go_back_interstitial_button',
        link_href: this.args.linkURL,
        app_id: this.args.appId,
      },
      true,
    );
    this.args.closeTab();
  }

  @action
  trackOpenUnsafeURLEvent(event: PointerEvent) {
    event.preventDefault();

    try {
      let url = new URL(this.args.linkURL);
      if (!['http:', 'https:'].includes(url.protocol)) {
        console.error('Invalid URL scheme detected');
        return;
      }
    } catch (e) {
      console.error('Invalid URL');
      return;
    }

    this.intercomEventService.trackAnalyticsEvent(
      {
        action: 'clicked',
        object: 'open_untrusted_url_interstitial_button',
        link_href: this.args.linkURL,
        app_id: this.args.appId,
      },
      true,
    );
    this.windowService.location.assign(sanitizeUrl(this.args.linkURL).string);
  }

  @action
  trackOpenMaliciousURLEvent(event: PointerEvent) {
    event.preventDefault();

    try {
      let url = new URL(this.args.linkURL);
      if (!['http:', 'https:'].includes(url.protocol)) {
        console.error('Invalid URL scheme detected');
        return;
      }
    } catch (e) {
      console.error('Invalid URL');
      return;
    }

    this.intercomEventService.trackAnalyticsEvent(
      {
        action: 'clicked',
        object: 'open_malicious_url_interstitial_button',
        link_href: this.args.linkURL,
        app_id: this.args.appId,
      },
      true,
    );

    this.windowService.location.assign(sanitizeUrl(this.args.linkURL).string);
  }

  @action
  toggleCheckbox() {
    this.acceptRiskIsChecked = !this.acceptRiskIsChecked;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Security::ExternalLinkShield': typeof ExternalLinkShield;
    'settings/security/external-link-shield': typeof ExternalLinkShield;
  }
}
