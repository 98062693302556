/* import __COLOCATED_TEMPLATE__ from './modal.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type IntlService from 'ember-intl/services/intl';

import type Attribute from 'embercom/models/attribute';
import type ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import { action } from '@ember/object';
import type ListOption from 'embercom/models/conversation-attributes/list-option';
import { post, put } from 'embercom/lib/ajax';
import {
  type FieldType,
  type TicketField,
  type UserField,
} from 'embercom/objects/standalone/data-fields/common';

interface Args {
  field: UserField | TicketField;
  onClose: () => void;
}

const FIELD_TYPE_MAPPING: Record<FieldType, string> = {
  text: 'text',
  textarea: 'text',
  checkbox: 'boolean',
  date: 'date',
  integer: 'number',
  decimal: 'decimal',
  regexp: 'text',
  dropdown: 'list',
  multiselect: 'list',
  lookup: 'relationship',
  tagger: 'list',
  id: 'string',
  string: 'string',
  address: 'string',
  email: 'string',
  phone: 'string',
  boolean: 'boolean',
  datetime: 'date',
  url: 'string',
  double: 'number',
  picklist: 'list',
};

export default class DataFieldModal extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare appService: any;
  @service declare store: any;
  @service declare notificationsService: any;
  @service declare intl: IntlService;

  get fieldType() {
    return this.intl.t(
      `standalone.data-field.modal.format.types.${FIELD_TYPE_MAPPING[this.args.field.type]}`,
    );
  }

  conversationAttribute: ConversationAttributeDescriptor | undefined;
  userAttribute: Attribute | undefined;

  constructor(owner: any, args: Args) {
    super(owner, args);

    if ('intercom_cvda' in this.args.field) {
      if (this.args.field.intercom_cvda) {
        this.conversationAttribute = this.store.push(
          this.store.normalize('conversation-attributes/descriptor', this.args.field.intercom_cvda),
        ) as ConversationAttributeDescriptor;
      }
    }

    if ('intercom_cda' in this.args.field) {
      if (this.args.field.intercom_cda) {
        this.userAttribute = this.store.push(
          this.store.normalize('attribute', this.args.field.intercom_cda),
        ) as Attribute;
      }
    }
  }

  @action async saveConversationAttribute() {
    if (this.conversationAttribute) {
      try {
        if (
          this.conversationAttribute.listOptions.every((option: ListOption) => option.description)
        ) {
          this.conversationAttribute.isClassificationAttribute = true;
        }

        await this.conversationAttribute.save();

        // @ts-expect-error we know this is a ticket field
        this.args.field.intercom_cvda = this.conversationAttribute.serialize();

        this.args.onClose();
      } catch (error) {
        this.notificationsService.notifyResponseError(error, {
          default: this.intl.t('standalone.data-field.modal.errors.save'),
        });
      }
    }
  }

  @action async syncTags() {
    try {
      await post('/ember/standalone/zendesk_data/synchronize_tags', {
        app_id: this.appService.app.id,
      });

      this.notificationsService.notifyConfirmation(
        this.intl.t('standalone.data-field.modal.notifications.tags-sync-started'),
      );
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('standalone.data-field.modal.errors.sync-tags'),
      });
    }
  }

  @action async remove() {
    if (this.userAttribute) {
      try {
        await put('/ember/data_attribute_settings/archive', {
          app_id: this.appService.app.id,
          id: this.userAttribute.cdaId,
        });

        // @ts-expect-error we know this is a user field
        this.args.field.intercom_cda = undefined;

        this.args.onClose();
      } catch (error) {
        this.notificationsService.notifyResponseError(error, {
          default: this.intl.t('standalone.data-field.modal.errors.remove'),
          412: this.intl.t('standalone.data-field.modal.errors.in-use'),
        });
      }
    } else if (this.conversationAttribute) {
      try {
        this.conversationAttribute.archived = true;
        this.conversationAttribute.order = 0;
        await this.conversationAttribute.save();

        // @ts-expect-error we know this is a ticket field
        this.args.field.intercom_cvda = undefined;

        this.args.onClose();
      } catch (error) {
        this.notificationsService.notifyResponseError(error, {
          default: this.intl.t('standalone.data-field.modal.errors.remove'),
          412: this.intl.t('standalone.data-field.modal.errors.in-use'),
        });
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::DataField::Modal': typeof DataFieldModal;
    'standalone/data-field/modal': typeof DataFieldModal;
  }
}
