/* import __COLOCATED_TEMPLATE__ from './sunshine.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type SunshineConfiguration from 'embercom/models/standalone/sunshine-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

export interface Args {
  isSaving?: boolean;
}

export default class SunshinePill extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;

  get isDisconnected() {
    return !this.sunshineConfig || this.sunshineConfig.isSunshineDisconnected;
  }

  get sunshineConfig(): SunshineConfiguration | undefined {
    return this.finStandaloneService.zendeskConfig?.sunshineIntegration;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Pills::Sunshine': typeof SunshinePill;
    'standalone/zendesk/pills/sunshine': typeof SunshinePill;
  }
}
