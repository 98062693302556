/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface Args {
  archivedAttributes: any[];
}

export default class CompanyAttributesMain extends Component<Args> {
  @service declare modalService: any;

  @action
  openAttributeCreationModal() {
    this.modalService.openModal('settings/modals/attribute-details', {
      attribute: null,
      allowArchival: false,
      showPeopleAndCompanyToggleTab: false,
      typeToCreate: 'company',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::CompanyAttributes::Main': typeof CompanyAttributesMain;
    'standalone::company-attributes::main': typeof CompanyAttributesMain;
  }
}
