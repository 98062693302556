/* import __COLOCATED_TEMPLATE__ from './locale-selector.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { DEFAULT_LOCALE } from 'embercom/services/intl';

export default class LocaleSelector extends Component {
  @service intl;
  @service appService;

  get localeList() {
    return [...Object.values(this.localeLabelMap)];
  }

  get localeLabelMap() {
    return this._getLocaleLabelMap();
  }

  @action changeLocale(locale) {
    this.args.onChangeLocale(locale);
  }

  get selectedLocale() {
    return this.localeLabelMap[this.args.inviteLocale || DEFAULT_LOCALE].value;
  }

  get selectedLocaleLabel() {
    return this.localeLabelMap[this.args.inviteLocale || DEFAULT_LOCALE].text;
  }

  _getLocaleLabelMap() {
    let localeForInvite = {};
    this.intl.supportedLanguages.forEach(({ locale, language }) => {
      localeForInvite[locale] = {
        value: locale,
        text: language,
      };
    });
    return localeForInvite;
  }
}
