/* import __COLOCATED_TEMPLATE__ from './format-and-options-cell.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import dataFormatMap from 'embercom/models/data/attributes/data-format-map';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  attribute: any;
}

export default class FormatAndOptionsCell extends Component<Args> {
  @service declare intl: IntlService;

  get formatName() {
    if (this.args.attribute.hasOptions) {
      return this.intl.t('settings.data-format-map.list');
    }

    return this.intl.t(
      dataFormatMap[this.args.attribute.type as keyof typeof dataFormatMap] ||
        'settings.data-format-map.unknown',
    );
  }

  get commaSeparatedOptions() {
    return this.args.attribute.options.map((option: any) => option.value).join(', ');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::CompanyAttributes::FormatAndOptionsCell': typeof FormatAndOptionsCell;
    'standalone::company-attributes::format-and-options-cell': typeof FormatAndOptionsCell;
  }
}
