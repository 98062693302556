/* import __COLOCATED_TEMPLATE__ from './activation.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import ENV from 'embercom/config/environment';
import type CustomIntlService from 'embercom/services/intl';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { tracked } from '@glimmer/tracking';
import { RESPONSE_PROVIDED_ERRORS } from 'embercom/objects/standalone/constants';

export interface Args {}

export default class StandaloneIntercomMessengerSetupActivation extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare notificationsService: {
    notify: (message: string, duration: number, key: string) => void;
    notifyConfirmation: (message: string, duration: number, key: string) => void;
    notifyResponseError: (
      e: Error,
      messages: { default: string },
      options: { responseProvidedErrors: number[] },
    ) => void;
  };
  @service declare intl: CustomIntlService;

  @tracked isSaving = false;

  @action async activateFinForMessenger() {
    this.isSaving = true;
    try {
      await this.finStandaloneService.intercomMessengerConfig.activate();
      this.notifyConfirmation(
        this.intl.t('standalone.intercom-messenger.activation.activation-success-notification'),
      );
    } catch (e) {
      console.error(e.message || e.jqXHR);
      this.notificationsService.notifyResponseError(
        e,
        {
          default: this.intl.t(
            'standalone.intercom-messenger.activation.activation-error-notification',
          ),
        },
        {
          responseProvidedErrors: RESPONSE_PROVIDED_ERRORS,
        },
      );
    }
    this.isSaving = false;
  }

  @action async deactivateFinForMessenger() {
    this.isSaving = true;
    try {
      await this.finStandaloneService.intercomMessengerConfig.deactivate();
      this.notifyInfo(
        this.intl.t('standalone.intercom-messenger.activation.deactivation-success-notification'),
      );
    } catch (e) {
      console.error(e.message || e.jqXHR);
      this.notificationsService.notifyResponseError(
        e,
        {
          default: this.intl.t(
            'standalone.intercom-messenger.activation.deactivation-error-notification',
          ),
        },
        {
          responseProvidedErrors: RESPONSE_PROVIDED_ERRORS,
        },
      );
    }
    this.isSaving = false;
  }

  private notifyConfirmation(message: string) {
    this.notificationsService.notifyConfirmation(
      message,
      ENV.APP._2000MS,
      'fin-standalone-activation',
    );
  }

  private notifyInfo(message: string) {
    this.notificationsService.notify(message, ENV.APP._2000MS, 'fin-standalone-activation');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::IntercomMessenger::Setup::Activation': typeof StandaloneIntercomMessengerSetupActivation;
  }
}
