/* import __COLOCATED_TEMPLATE__ from './salesforce.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

interface Args {}

export default class StandaloneChannelsOverviewSalesforce extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;

  get salesforceConfig() {
    return this.finStandaloneService.salesforceConfig!;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Channels::Overview::Salesforce': typeof StandaloneChannelsOverviewSalesforce;
    'standalone/channels/overview/salesforce': typeof StandaloneChannelsOverviewSalesforce;
  }
}
