/* import __COLOCATED_TEMPLATE__ from './fin-identity.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable @intercom/intercom/no-bare-strings */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { type PulseAccordion } from 'glint/pulse';
import type SalesforceConfiguration from 'embercom/models/standalone/salesforce-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {
  accordion: PulseAccordion;
}

export default class StandaloneSalesforceFinIdentity extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare notificationsService: $TSFixMe;

  @tracked newAgentId?: string;

  @action openSectionChanged() {
    this.newAgentId = undefined;
  }

  get salesforceConfig(): SalesforceConfiguration {
    return this.finStandaloneService.salesforceConfig!;
  }

  get salesforceSetupData() {
    return this.finStandaloneService.salesforceSetupData;
  }

  get isLoading() {
    return this.finStandaloneService.isLoadingSalesforceSetupData && !this.adminOptions.length;
  }

  get adminOptions() {
    let admins = this.salesforceSetupData.admins?.map((admin) => ({
      text: admin.name,
      value: admin.id,
    }));
    return admins || [];
  }

  get selectedAgentName(): string | undefined {
    if (this.isLoading) {
      return undefined;
    }

    return this.adminOptions.find((option) => option.value === this.selectedAgentId)?.text;
  }

  get selectedAgentId(): string | undefined {
    if (this.salesforceConfig.finSalesforceUserId) {
      return this.salesforceConfig.finSalesforceUserId;
    }
    return undefined;
  }

  @action setAgentId(agentId: string) {
    this.newAgentId = agentId;
  }

  @action async updateIdentity() {
    try {
      if (this.newAgentId) {
        await this.salesforceConfig.setFinUser(this.newAgentId);
      }
      this.notificationsService.notifyConfirmation('Successfully updated Fin identity settings');
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: `Something went wrong and we couldn't update your Fin identity settings. Please try again`,
      });
      console.error(e);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Salesforce::Setup::FinIdentity': typeof StandaloneSalesforceFinIdentity;
    'standalone/salesforce/setup/fin-identity': typeof StandaloneSalesforceFinIdentity;
  }
}
