/* import __COLOCATED_TEMPLATE__ from './activation.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import ENV from 'embercom/config/environment';
import { RESPONSE_PROVIDED_ERRORS } from 'embercom/objects/standalone/constants';
import type IntlService from 'ember-intl/services/intl';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

interface Args {}

export default class StandaloneZendeskSetupTicketsActivation extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intl: IntlService;
  @service declare notificationsService: {
    notifyConfirmation: (message: string, timeout: number, id: string) => void;
    notify: (message: string, timeout: number, id: string) => void;
    notifyResponseError: (
      error: Error,
      options: { default: string },
      context: { responseProvidedErrors: number[] },
    ) => void;
  };

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig!;
  }

  get ticketsSetupData() {
    return this.finStandaloneService.zendeskTicketsSetupData;
  }

  get isLoading() {
    return this.finStandaloneService.isLoadingZendeskTicketsSetupData;
  }

  @action async activateFinForTickets() {
    try {
      await this.zendeskConfig.activateForTickets();
      this.notifyConfirmation(
        this.intl.t('standalone.zendesk.deploy.tickets.activation.activation-success'),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(
        e,
        {
          default: this.intl.t('standalone.zendesk.deploy.tickets.activation.activation-error'),
        },
        {
          responseProvidedErrors: RESPONSE_PROVIDED_ERRORS,
        },
      );
      console.error(e.message || e.jqXHR);
    }
  }

  @action async deactivateFinForTickets() {
    try {
      await this.zendeskConfig.deactivateForTickets();
      this.notifyInfo(this.intl.t('standalone.zendesk.deploy.tickets.deactivation-success'));
    } catch (e) {
      this.notificationsService.notifyResponseError(
        e,
        {
          default: this.intl.t('standalone.zendesk.deploy.tickets.deactivation-error'),
        },
        {
          responseProvidedErrors: RESPONSE_PROVIDED_ERRORS,
        },
      );
      console.error(e.message || e.jqXHR);
    }
  }

  notifyConfirmation(message: string) {
    this.notificationsService.notifyConfirmation(
      message,
      ENV.APP._2000MS,
      'fin-standalone-activation',
    );
  }

  notifyInfo(message: string) {
    this.notificationsService.notify(message, ENV.APP._2000MS, 'fin-standalone-activation');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::Tickets::Activation': typeof StandaloneZendeskSetupTicketsActivation;
    'standalone/zendesk/deploy/tickets/activation': typeof StandaloneZendeskSetupTicketsActivation;
  }
}
