/* import __COLOCATED_TEMPLATE__ from './zendesk.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

interface Args {}

export default class StandaloneChannelsOverviewZendesk extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;

  get zendeskConfig() {
    return this.finStandaloneService.zendeskConfig!;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Channels::Overview::Zendesk': typeof StandaloneChannelsOverviewZendesk;
    'standalone/channels/overview/zendesk': typeof StandaloneChannelsOverviewZendesk;
  }
}
