/* import __COLOCATED_TEMPLATE__ from './standalone-add-knowledge-modal.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { EntityType } from 'embercom/models/data/entity-types';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';
import { type FinAIType } from 'embercom/lib/knowledge-hub/constants';
import { type ViewId } from 'embercom/components/knowledge-hub/setup-modal';
import { action } from '@ember/object';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type Store from '@ember-data/store';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Args {
  modal: any;
  title: string;
  onNavigationBack: () => void;
  onNavigationNext: (viewId: ViewId) => void;
  showBackButton: boolean;
  onModalClose: () => void;
  context?: {
    folder?: string;
    entityTypes?: EntityType[];
    aiTypeFilter?: FinAIType;
    includeNonKnowledgeBaseContent?: boolean;
    openInNewTab?: boolean;
  };
}

const CONTENT_ENTITY_TYPES = [
  EntityType.ContentSnippet,
  EntityType.FileSourceContent,
  EntityType.Answer,
];

export default class StandaloneAddKnowledgeModal extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare intl: IntlService;
  @service declare store: Store;

  get contentEntityTypes() {
    return CONTENT_ENTITY_TYPES;
  }

  get contentTiles() {
    let localizationPrefix = 'knowledge-hub.filterable-list.setup-modal.views.select-content.tiles';
    return this.contentEntityTypes.map((entityType) => {
      return {
        id: `${entityType}`,
        title: this.intl.t(`${localizationPrefix}.${objectNames[entityType]}.title`),
        entityType,
        description: this.intl.t(
          `${localizationPrefix}.${objectNames[entityType]}.description-ai-agent`,
        ),
      };
    });
  }

  get importTiles() {
    let tiles = [
      this.createTile({
        id: 'zendesk',
        viewId: 'zendesk-sync',
        titleKey:
          'knowledge-hub.filterable-list.setup-modal.views.select-content.tiles.zendesk.standalone-title',
        descriptionKey:
          'knowledge-hub.filterable-list.setup-modal.views.select-content.tiles.zendesk.description-ai-agent',
        icon: 'zendesk' as InterfaceIconName,
        entityType: EntityType.ArticleContent,
      }),
      this.createTile({
        id: 'salesforce_knowledge',
        viewId: 'salesforce-sync',
        titleKey:
          'knowledge-hub.filterable-list.setup-modal.views.select-content.tiles.salesforce.title',
        descriptionKey:
          'knowledge-hub.filterable-list.setup-modal.views.select-content.tiles.salesforce.description-ai-agent',
        icon: 'salesforce' as InterfaceIconName,
        entityType: EntityType.ExternalContent,
      }),
      this.createTile({
        id: 'external-content',
        viewId: 'external-content',
        titleKey:
          'knowledge-hub.filterable-list.setup-modal.views.select-content.tiles.external-content.standalone-title',
        descriptionKey:
          'knowledge-hub.filterable-list.setup-modal.views.select-content.tiles.external-content.description-ai-agent',
        entityType: EntityType.ExternalContent,
      }),
    ];

    return tiles.filter((tile) => {
      switch (tile.id) {
        case 'zendesk':
          return this.appService.app.hasStandalonePlatform('zendesk');
        case 'salesforce_knowledge':
          return this.appService.app.canUseIntegrationAppForSalesforce;
        default:
          return true;
      }
    });
  }

  private createTile({
    id,
    viewId,
    titleKey,
    descriptionKey,
    icon,
    entityType,
  }: {
    id: string;
    viewId: string;
    titleKey: string;
    descriptionKey?: string;
    icon?: InterfaceIconName;
    entityType: EntityType;
  }) {
    return {
      id,
      viewId,
      title: this.intl.t(titleKey),
      description: descriptionKey ? this.intl.t(descriptionKey) : undefined,
      icon,
      entityType,
    };
  }

  @action async navigateToView(viewId: ViewId, entityType?: EntityType) {
    try {
      await this.knowledgeHubService.ensurePermissions(entityType);
    } catch (error) {
      this.args.onModalClose();
      return;
    }
    this.args.onNavigationNext(viewId);
  }

  @action async createContent(entityType: EntityType) {
    if (entityType === EntityType.FileSourceContent) {
      await this.navigateToView('file-upload', entityType);
      return;
    }

    this.knowledgeHubService.createContent(
      entityType,
      { folder_id: this.args.context?.folder },
      this.args.context?.openInNewTab,
    );
    this.args.onModalClose();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Channels::StandaloneAddKnowledgeModal': typeof StandaloneAddKnowledgeModal;
    'standalone/channels/standalone-add-knowledge-modal': typeof StandaloneAddKnowledgeModal;
  }
}
