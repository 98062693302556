/* import __COLOCATED_TEMPLATE__ from './navbar.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable @intercom/intercom/no-bare-strings */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

import { NavItem, type NavItemInputs, NavTree } from 'embercom/objects/standalone/nav';
import type IntlService from 'ember-intl/services/intl';

interface Args {}

export default class StandaloneNavbar extends Component<Args> {
  @service declare appService: any;
  @service declare intl: IntlService;

  get app() {
    return this.appService.app;
  }

  @tracked links: NavItem[] = [];

  settingsLink: NavItem;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    let tree = new NavTree(owner);

    let trainChildren = [];
    if (this.app.canShowFinNuxSAPages) {
      trainChildren.push({
        route: 'apps.app.knowledge-hub.overview',
        label: 'Content',
        activeRoutes: [
          'apps.app.knowledge-hub.all-content',
          'apps.app.knowledge-hub.edit',
          'apps.app.knowledge-hub.folder',
          'apps.app.knowledge-hub.loading',
          'apps.app.knowledge-hub.new',
          'apps.app.knowledge-hub.view',
          'apps.app.knowledge-hub.overview',
        ],
      });
    } else {
      trainChildren.push({
        route: 'apps.app.knowledge-hub.overview',
        label: 'Sources',
      });
      trainChildren.push({
        route: 'apps.app.knowledge-hub.all-content',
        label: 'Content',
        activeRoutes: [
          'apps.app.knowledge-hub.all-content',
          'apps.app.knowledge-hub.edit',
          'apps.app.knowledge-hub.folder',
          'apps.app.knowledge-hub.loading',
          'apps.app.knowledge-hub.new',
          'apps.app.knowledge-hub.view',
        ],
      });
    }

    let navItems = [
      {
        route: 'apps.app.knowledge-hub.overview',
        icon: 'article',
        label: 'Train',
        children: [
          ...trainChildren,
          {
            route: 'apps.app.standalone.knowledge.custom-answers',
            label: 'Custom Answers',
            requiredFeatures: ['team-standalone-custom-answers'],
          },
          {
            route: 'apps.app.standalone.guidance',
            label: 'Guidance',
            requiredFeatures: ['fin-guidance-closed-beta', 'team-standalone-fin-guidance'],
          },
          {
            route: 'apps.app.standalone.processes',
            label: 'Processes',
            requiredFeatures: ['answerbot-fin-actions-workflow-write-actions'],
          },
          {
            route: 'apps.app.standalone.knowledge.suggestions',
            label: 'Suggestions',
            requiredFeatures: ['conversation-extraction-external-sources'],
          },
        ],
      },

      {
        route: 'apps.app.standalone.testing',
        icon: 'test',
        label: 'Test',
        requiredFeatures: ['answerbot-fin-playground'],
      },
      {
        route: 'apps.app.standalone.channels',
        icon: 'rocket-ship',
        label: 'Deploy',
        // eslint-disable-next-line object-shorthand
        customOnClick: function () {
          let self = this as NavItem;
          self.root.setForcedActiveItem(self);
          self.router.transitionTo(`${self.route}.overview`);
        },
        children: [
          {
            route: 'apps.app.standalone.channels.intercom.messenger',
            label: 'Intercom Messenger',
          },
          {
            route: 'apps.app.standalone.channels.salesforce.cases',
            label: 'Salesforce Cases',
            platforms: ['salesforce'],
          },
          {
            route: 'apps.app.standalone.channels.zendesk.tickets',
            label: 'Zendesk tickets',
            platforms: ['zendesk'],
          },
          {
            route: 'apps.app.standalone.channels.zendesk.messaging',
            label: 'Zendesk Messaging',
            platforms: ['zendesk'],
          },
        ],
      },
      {
        route: 'apps.app.standalone.reports',
        icon: 'column-chart',
        label: 'Report',
        children: [
          {
            route: 'apps.app.standalone.reports',
            label: 'Performance',
          },
          {
            route: 'apps.app.standalone.content-gaps',
            label: 'Content gaps',
          },
        ],
      },
    ] as NavItemInputs[];

    this.links = navItems.map((item) => new NavItem(tree, owner, item));

    this.settingsLink = new NavItem(tree, owner, {
      route: 'apps.app.settings',
      icon: 'settings',
      label: this.intl.t('standalone.navbar.settings'),
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Navbar': typeof StandaloneNavbar;
    'standalone::navbar': typeof StandaloneNavbar;
  }
}
