/* import __COLOCATED_TEMPLATE__ from './copilot-adapter.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { htmlUnescape } from 'embercom/lib/html-unescape';

interface Args {}

export default class CopilotAdapter extends Component<Args> {
  @service declare appService: $TSFixMe;

  @action addBlocksToComposer(blocks: any[]) {
    let text = blocks
      .reject((block) => block.type === 'sources')
      .map((block) => {
        if (block.items) {
          return block.items.map((item: any) => `• ${item}`).join('\n');
        }
        return block.text ?? '';
      })
      .join('\n');

    text = htmlUnescape(text);
    text = text.replace(/<[^>]*>/g, '');

    window.parent.postMessage(
      {
        type: 'ADD_BLOCKS_TO_COMPOSER',
        payload: {
          text,
        },
      },
      '*',
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Adapters::CopilotAdapter': typeof CopilotAdapter;
  }
}
