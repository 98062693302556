/* import __COLOCATED_TEMPLATE__ from './api-connection.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable @intercom/intercom/no-bare-strings */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type SalesforceConfiguration from 'embercom/models/standalone/salesforce-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { type PulseAccordion } from 'glint/pulse';

interface Args {
  accordion: PulseAccordion;
  onCasesChannel: boolean;
}

export default class StandaloneSalesforceApiConnection extends Component<Args> {
  @service declare appService: any;
  @service declare finStandaloneService: FinStandaloneService;

  get salesforceConfig(): SalesforceConfiguration {
    return this.finStandaloneService.salesforceConfig!;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Salesforce::Setup::ApiConnection': typeof StandaloneSalesforceApiConnection;
    'standalone/salesforce/setup/api-connection': typeof StandaloneSalesforceApiConnection;
  }
}
