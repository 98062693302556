/* import __COLOCATED_TEMPLATE__ from './data-fields.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import {
  type UserField,
  type TicketField,
  type OrganizationField,
} from 'embercom/objects/standalone/data-fields/common';
import { action } from '@ember/object';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';

interface Args {}

export default class StandaloneZendeskDataFields extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare store: Store;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.finStandaloneService.loadZendeskDataFields).perform();
  }

  get zendeskConfig() {
    return this.finStandaloneService.zendeskConfig;
  }

  get synchronizedUserFields(): Array<UserField> {
    return this.finStandaloneService.zendeskDataFields.userFields.filter(
      (field) => field.required || field.intercom_cda?.identifier || field.isSynchronizing,
    );
  }

  get unsynchronizedUserFields(): Array<UserField> {
    return this.finStandaloneService.zendeskDataFields.userFields.filter(
      (field) => !field.required && !field.intercom_cda?.identifier && !field.isSynchronizing,
    );
  }

  get synchronizedTicketFields(): Array<TicketField> {
    return this.finStandaloneService.zendeskDataFields.ticketFields.filter(
      (field) => field.required || field.intercom_cvda?.id || field.isSynchronizing,
    );
  }

  get unsynchronizedTicketFields(): Array<TicketField> {
    return this.finStandaloneService.zendeskDataFields.ticketFields.filter(
      (field) => !field.required && !field.intercom_cvda?.id && !field.isSynchronizing,
    );
  }

  get synchronizedOrganizationFields(): Array<OrganizationField> {
    return this.finStandaloneService.zendeskDataFields.organizationFields.filter(
      (field) => field.required || field.intercom_cda?.identifier || field.isSynchronizing,
    );
  }

  get unsynchronizedOrganizationFields(): Array<OrganizationField> {
    return this.finStandaloneService.zendeskDataFields.organizationFields.filter(
      (field) => !field.required && !field.intercom_cda?.identifier && !field.isSynchronizing,
    );
  }

  @action async addZendeskUserField(userField: UserField) {
    await this.finStandaloneService.addZendeskUserField(userField);
  }

  @action async addZendeskTicketField(ticketField: TicketField) {
    await this.finStandaloneService.addZendeskTicketField(ticketField);
  }

  @action async addZendeskOrganizationField(organizationField: OrganizationField) {
    await this.finStandaloneService.addZendeskOrganizationField(organizationField);
  }

  @tracked selectedField?: UserField | TicketField | OrganizationField;

  @action didCloseModal() {
    this.selectedField = undefined;
    this.finStandaloneService.notifyPropertyChange('zendeskDataFields');
  }

  @action selectField(field: UserField | TicketField | OrganizationField) {
    this.selectedField = field;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Settings::DataFields': typeof StandaloneZendeskDataFields;
    'standalone/Zendesk/Settings/DataFields': typeof StandaloneZendeskDataFields;
  }
}
