/* import __COLOCATED_TEMPLATE__ from './email-domain-verification.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type SalesforceConfiguration from 'embercom/models/standalone/salesforce-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { type PulseAccordion } from 'glint/pulse';

interface Args {
  accordion: PulseAccordion;
}

export default class StandaloneSalesforceSetupEmailDomainVerification extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare appService: $TSFixMe;

  @tracked openSectionId: number | null = null;

  get salesforceConfig(): SalesforceConfiguration {
    return this.finStandaloneService.salesforceConfig!;
  }

  get app() {
    return this.appService.app;
  }

  get appWithIntercomrades() {
    return this.app.admins.any((admin: any) => admin.email.endsWith('intercom.io'));
  }

  get dkimSettings() {
    let dkimSettings = this.app.dkim_settings;
    return dkimSettings.reject(
      (setting: any) =>
        setting.domain.endsWith('.hirefin.com') ||
        (setting.domain.endsWith('intercom.io') && this.appWithIntercomrades),
    );
  }

  get emailAddressHash() {
    let addressHash = new Map();
    this.app.customEmailAddresses.forEach((address: $TSFixMe) => {
      let domain = address.email
        .substring((address.email.lastIndexOf('@') as number) + 1)
        .toLowerCase();

      if (!addressHash.has(domain)) {
        addressHash.set(domain, []);
      }
      addressHash.get(domain).push({
        email: address.email,
        verified: address.verified,
        id: address.id,
      });
    });

    return addressHash;
  }

  get hydratedDomains() {
    let emailAddressHash = this.emailAddressHash;
    let domains: any[] = [];

    this.dkimSettings.forEach((record: any) => {
      let customBounceSetting = this.app.customBounceSettings.find((bounceSetting: any) => {
        return bounceSetting.rootDomain === record.domain;
      });

      let authenticated =
        record.validRecordExists &&
        customBounceSetting?.validRecordExists &&
        record.validDmarcExists;

      domains.push({
        authenticated,
        dkimRecord: record,
        customBounceSetting,
        customAddresses: emailAddressHash.get(record.domain.toLowerCase()),
      });
    });
    return domains;
  }

  @action
  onOpenSectionChange(newOpenSectionId: number | null) {
    this.openSectionId = newOpenSectionId;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Salesforce::Setup::EmailDomainVerification': typeof StandaloneSalesforceSetupEmailDomainVerification;
  }
}
