/* import __COLOCATED_TEMPLATE__ from './trigger-config.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { type PulseAccordion } from 'glint/pulse';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  accordion: PulseAccordion;
}

export default class StandaloneZendeskTriggerConfig extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intl: IntlService;
  @service declare notificationsService: {
    notifyConfirmation(message: string): void;
    notifyResponseError(error: Error, options: { default: string }): void;
  };

  @tracked manageAssignmentTrigger = this.zendeskConfig.ticketsIntegration.manageAssignmentTrigger;

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig!;
  }

  get ticketsSetupData() {
    return this.finStandaloneService.zendeskTicketsSetupData;
  }

  get isLoading() {
    return this.finStandaloneService.isLoadingZendeskTicketsSetupData;
  }

  get triggerUrl() {
    if (!this.ticketsSetupData.live_trigger?.id) {
      return null;
    }

    return `https://${this.zendeskConfig.subdomain}.zendesk.com/admin/objects-rules/rules/triggers/${this.ticketsSetupData.live_trigger.id}`;
  }

  @action openSectionChanged() {
    this.manageAssignmentTrigger = this.zendeskConfig.ticketsIntegration.manageAssignmentTrigger;
  }

  @action
  toggleManageAssignmentTrigger() {
    this.manageAssignmentTrigger = !this.manageAssignmentTrigger;
  }

  @action
  async updateManageAssignmentTrigger() {
    try {
      await this.zendeskConfig.ticketsIntegration.updateManageAssignmentTrigger(
        this.manageAssignmentTrigger,
      );
      this.notificationsService.notifyConfirmation(
        this.intl.t('standalone.zendesk.deploy.tickets.trigger-config.update-success'),
      );
    } catch (error) {
      console.error(error.message || error.jqXHR);
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('standalone.zendesk.deploy.tickets.trigger-config.update-error'),
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::Tickets::TriggerConfig': typeof StandaloneZendeskTriggerConfig;
    'standalone/zendesk/deploy/tickets/trigger-config': typeof StandaloneZendeskTriggerConfig;
  }
}
