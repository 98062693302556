/* import __COLOCATED_TEMPLATE__ from './conditions-editor.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import type Condition from 'embercom/models/conversation-attributes/condition';
import type ListOption from 'embercom/models/conversation-attributes/list-option';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import { schedule } from '@ember/runloop';
import scrollIntoViewIfNotVisible from 'embercom/lib/scroll-into-view-if-not-visible';

interface Args {
  targetAttribute: ConversationAttributeDescriptor;
  allAttributes: ConversationAttributeDescriptor[];
  onClose?: () => void;
  onSave?: () => void;
}

export default class ConditionsEditor extends Component<Args> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare intercomConfirmService: IntercomConfirmService;

  @action availableDependentAttributes(condition: Condition) {
    let usedAttributes = this.args.targetAttribute.dependentConditions
      .rejectBy('isDeleted')
      .filterBy('controllingListOptionId', condition.controllingListOptionId)
      .mapBy('descriptorId');
    return this.args.allAttributes
      .rejectBy('isBuiltIn')
      .rejectBy('archived')
      .rejectBy('id', this.args.targetAttribute.id)
      .sortBy('order')
      .map((attribute) => {
        let additionalOptions = {};
        if (
          attribute.id !== condition.descriptorId?.toString() &&
          usedAttributes.includes(attribute.id)
        ) {
          additionalOptions = {
            isDisabled: true,
            tooltip: {
              text: this.intl.t(
                'settings.conversation-attributes.conditions-side-drawer.already-selected-error',
              ),
              isDelayed: false,
            },
          };
        }

        return {
          text: attribute.displayName,
          value: attribute.id,
          ...additionalOptions,
        };
      });
  }

  get visibleConditions() {
    return this.args.targetAttribute.dependentConditions
      .reject((condition: Condition) => condition.descriptor && condition.descriptor.archived)
      .filterBy('isDeleted', false);
  }

  get canAddCondition() {
    return this.visibleConditions.length < 100;
  }

  @action conditionalValues(condition: Condition) {
    if (!condition.descriptorId) {
      return [];
    }
    let descriptor = this.args.allAttributes.findBy('id', condition.descriptorId.toString());
    if (!descriptor || descriptor.dataType !== 'list') {
      return [];
    }

    return [
      {
        text: this.intl.t('settings.conversation-attributes.conditions-side-drawer.all-options'),
        value: '',
        isSelected: condition.descriptorListOptionIds.length === 0,
      },
    ].concat(
      descriptor.listOptions.rejectBy('archived').map((option: ListOption) => {
        return {
          text: option.label,
          value: option.id,
          isSelected: condition.descriptorListOptionIds.includes(option.id),
        };
      }),
    );
  }

  @action onAddCondition() {
    this.store.createRecord('conversation-attributes/condition', {
      controllingDescriptorId: this.args.targetAttribute.id,
      controllingListOptionId: undefined,
      descriptorListOptionIds: [],
      controllingDescriptor: this.args.targetAttribute,
    });
    schedule('afterRender', () => {
      scrollIntoViewIfNotVisible($('[data-condition]:last').get(0));
    });
  }

  @action onRemoveCondition(index: number) {
    this.args.targetAttribute.dependentConditions.objectAt(index).deleteRecord();
    this.args.targetAttribute.dependentConditions = this.args.targetAttribute.dependentConditions;
  }

  @action onSelectDependentAttribute(index: number, value: string) {
    let descriptor = this.args.allAttributes.findBy('id', value);
    let condition = this.args.targetAttribute.dependentConditions.objectAt(index);
    condition.descriptorId = value;
    condition.descriptor = descriptor;
    condition.descriptorListOptionIds = [];
  }

  @action onSelectControllingAttributeListOption(index: number, value: string) {
    this.args.targetAttribute.dependentConditions.objectAt(index).controllingListOptionId = value;
  }

  @action onSelectConditionalValues(index: number, options: string[]) {
    let currentValue = this.args.targetAttribute.dependentConditions
      .objectAt(index)
      .descriptorListOptionIds.toArray();
    if (options.includes('') && currentValue.length > 0) {
      this.args.targetAttribute.dependentConditions.objectAt(index).descriptorListOptionIds = [];
    } else {
      this.args.targetAttribute.dependentConditions.objectAt(index).descriptorListOptionIds =
        options.filter((option) => option !== '');
    }
  }

  get optionsToDescriptorMap() {
    return this.args.targetAttribute.dependentConditions.reduce(
      (acc: Record<string, any>, condition: Condition) => {
        if (!condition.descriptorId || !condition.controllingListOptionId) {
          return acc;
        }

        if (!acc[condition.controllingListOptionId]) {
          acc[condition.controllingListOptionId] = [];
        }

        acc[condition.controllingListOptionId].push(condition.descriptorId);
        return acc;
      },
      {} as Record<string, any>,
    );
  }

  @action controllingAttributeListOptionsItems(condition: Condition) {
    return this.args.targetAttribute.listOptions
      .filter((option: ListOption) => option.label.length > 0)
      .rejectBy('archived')
      .map((option: ListOption) => {
        let additionalOptions = {};
        if (
          option.id !== condition.controllingListOptionId &&
          this.optionsToDescriptorMap[option.id] &&
          this.optionsToDescriptorMap[option.id].includes(condition.descriptorId)
        ) {
          additionalOptions = {
            isDisabled: true,
            tooltip: {
              text: this.intl.t(
                'settings.conversation-attributes.conditions-side-drawer.already-selected-error',
              ),
              isDelayed: false,
            },
          };
        }

        return {
          text: option.label,
          value: option.id,
          ...additionalOptions,
        };
      });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::ConversationAttributes::ConditionsEditor': typeof ConditionsEditor;
    'settings/conversation-attributes/conditions-editor': typeof ConditionsEditor;
  }
}
