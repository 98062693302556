/* import __COLOCATED_TEMPLATE__ from './sunshine.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { inject as service } from '@ember/service';

interface Args {}

export default class StandaloneZendeskDeploySunshine extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;

  get zendeskConfig() {
    return this.finStandaloneService.zendeskConfig!;
  }

  @tracked openSectionId?: string;

  @action setOpenSectionId(id: string | undefined) {
    this.openSectionId = id;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::Sunshine': typeof StandaloneZendeskDeploySunshine;
    'standalone/zendesk/deploy/sunshine': typeof StandaloneZendeskDeploySunshine;
  }
}
