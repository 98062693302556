/* import __COLOCATED_TEMPLATE__ from './language-select.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

export interface Args {
  changeLocale: (newLocale: any) => void;
}
interface Signature {
  Args: Args;
  Blocks: any;
}

export default class LanguageSelect extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare media: any;

  get selectedLocale() {
    return this.intl.primaryLocale;
  }

  get locale() {
    return this.localeList.filter(
      (item) => item.value.toUpperCase() === this.selectedLocale.toUpperCase(),
    )[0];
  }

  get localeList() {
    return this.intl.supportedLanguages.map(({ locale: value, language: text }) => {
      return {
        value,
        text,
        onSelectItem: () => {
          this.args.changeLocale(value);
        },
      };
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::LanguageSelect': typeof LanguageSelect;
    'signup/teams/language-select': typeof LanguageSelect;
  }
}
